<template>
    <div v-show="!cartUpdating" class="totals-container p-4 text-center text-sm-start">
        <strong>{{ $t('cart.total_sum') }}</strong>
        <div v-show="componentType === 'cart' && extraFees" class="mt-1">
            <span v-for="extraFee in extraFees" :key="extraFee.product_id">
                <p v-if="extraFeeEnabled(extraFee)" class="small m-0">
                    {{ extraFee[`description_${$i18n.locale}`] }}
                    {{ extraFee.price_incl_tax | currency }}
                </p>
            </span>
        </div>
        <div class="row mt-2">
            <div :class="{
                    'pr-0': true,
                    'col-sm-6 col-12': componentType === 'cart',
                    'col-12 col-sm-6 text-nowrap text-center text-sm-start': componentType === 'order'
                }">
                <p class="mb-0" id="total-without-vat">
                    {{ totalWithOutVat | currency }} <span class="small"> ({{ $t('cart.vat_0') }})</span>
                </p>
                <span class="small" v-if="showSmallDeliveryFeeInfo">
                    + {{ $t('order.smalldeliveryfee') }} {{ smallDeliveryFeeProduct.price_excl_tax | currency }}</span>
            </div>
            <div class="pr-0 col-12 col-sm-6 text-center text-sm-start">
                <p class="mb-0" id="total-with-vat">
                    {{ totalWithVat | currency }} <span class="small"> ({{ $t('cart.incl_vat') }})</span>
                </p>
                <span class="small" v-if="showSmallDeliveryFeeInfo">
                    + {{ $t('order.smalldeliveryfee') }} {{ smallDeliveryFeeProduct.price_incl_tax | currency }}</span>
            </div>
        </div>
        <div class="primary actions d-print-none my-2">
            <div v-if="componentType === 'order'">
                <button class="btn btn-primary foodstock-btn w-100" @click="orderAgain()">{{ $t('button.move_to_cart')
                    }}</button>
            </div>
            <div v-else>
                <button class="foodstock-btn btn-primary w-100" :disabled="amountsNotValid || isOrdering"
                    @click="orderItems()">{{
                    $t('button.order_items') }} <div v-if="isOrdering"
                        class="spinner-border spinner-border-sm text-primary" role="status"></div> </button>
            </div>
        </div>
        <order-again-cart-not-empty v-if="showOrderAgainModal" :cart-not-empty="cartNotEmpty"
            :toggleModal="toggleModal" />
        <div class="minimum-order-amount-info small text-center pt-1" v-if="showSmallDeliveryFeeInfo">
            {{ $t('order.minimumamount_start') }} {{ minimumOrderAmount | currency }} {{ $t('order.minimumamount_end')
            }}
            ({{ smallDeliveryFeeProduct.price_excl_tax | currency }})
        </div>
    </div>
</template>

<script>
    import OrderAgainCartNotEmpty from '@/components/modals/OrderAgainCartNotEmpty';

    export default {
        props: {
            totals: {
                type: Object
            },
            componentType: {
                type: String
            }
        },
        components: { OrderAgainCartNotEmpty },
        data() {
            return {
                cartNotEmpty: 0,
                minOrderAmountEnabled: true,
                showOrderAgainModal: false
            };
        },
        mounted() {
          this.$store.commit('Modal/ENABLE_ORDER_BUTTON');
        },
      computed: {
            itemsInCart() {
                return this.$store.getters['Cart/GET_ITEMS_IN_CART'];
            },
            cartUpdating() {
                return this.$store.getters['Cart/CART_UPDATING'];
            },
            extraFees() {
                return this.$store.getters['ExtraFees/GET_EXTRA_FEES'];
            },
            amountsNotValid() {
                return this.$store.getters['Cart/ITEM_AMOUNTS_NOT_VALID'];
            },
            isOrdering() {
              return this.$store.getters['Modal/ORDER_BUTTON_DISABLED'];
            },
            minOrderAmount() {
                return this.$store.getters['ExtraFees/MIN_ORDER_AMOUNT'];
            },
            totalWithVat() {
                let total = this.totals.withVat;

                if (this.componentType === 'cart' && this.extraFees) {
                    this.extraFees.forEach((extraFee) => {
                        if (this.extraFeeEnabled(extraFee)) {
                            total += parseFloat(extraFee.price_incl_tax);
                        }
                    });
                }
                return total;
            },
            totalWithOutVat() {
                let total = this.totals.withOutVat;

                if (this.componentType === 'cart' && this.extraFees) {
                    this.extraFees.forEach((extraFee) => {
                        if (this.extraFeeEnabled(extraFee)) {
                            total += parseFloat(extraFee.price_excl_tax);
                        }
                    });
                }
                return total;
            },
            minOrderAmountFeeWithVat() {
                return this.$store.getters['ExtraFees/MIN_ORDER_FEE_WITH_VAT'];
            },
            minOrderAmountFeeWithOutVat() {
                return this.$store.getters['ExtraFees/MIN_ORDER_FEE_WITH_OUT_VAT'];
            },
            minimumOrderAmount() {
                return this.$store.getters['Auth/GET_MINIMUM_ORDER_AMOUNT'];
            },
            orderAmountLessThanMinimum() {
                return this.totalWithOutVat < this.minimumOrderAmount;
            },
            smallDeliveryFeeProduct() {
                return this.$store.getters['Catalog/GET_SMALL_DELIVERY_FEE_PRODUCT'];
            },
            smallDeliveryFeeInCart() {
                return !!this.itemsInCart.find((item) => item.product_id === 'P9996');
            },
            showSmallDeliveryFeeInfo() {
                return (
                    !!this.$store.getters['Catalog/GET_SMALL_DELIVERY_FEE_PRODUCT'] &&
                    this.orderAmountLessThanMinimum &&
                    !this.smallDeliveryFeeInCart &&
                    this.componentType === 'cart'
                );
            }
        },
      methods: {
            toggleModal () {
                this.showOrderAgainModal = !this.showOrderAgainModal
            },
            orderItems() {
                // Button on the shopping cart
                this.$store.commit('Modal/DISABLE_ORDER_BUTTON');
                this.$store.commit('Modal/DISABLE_CONFIRM_ORDER_LOADING');
                this.$store.commit('Modal/ACTIVATE_CONFIRM_ORDER');
            },
            orderHelper() {
                if (!this.$store.getters['Cart/EMPTY_CART']) {
                  this.showOrderAgainModal = true
                } else {
                    this.$store.dispatch('Order/ORDER_AGAIN').then(() => {
                        this.$router.push({ name: 'categories', params: { orderAgain: true } });
                    });
                }
            },
            orderAgain() {
                if (document.documentElement.clientWidth < 768) {
                    this.$store.dispatch('NavigationDrawer/TOGGLE_ORDER_TEMPLATE');
                }

                if (!this.$store.getters['Catalog/GET_CATALOG']) {
                    this.$store.dispatch('Catalog/INIT_CATALOG').then(() => {
                        this.orderHelper();
                    });
                } else {
                    this.orderHelper();
                }
            },
            extraFeeEnabled(extraFee) {
                let showFee = true;
                if (extraFee.min_order_amount && !this.minOrderAmountEnabled) {
                    showFee = false;
                }
                return showFee;
            }
        },
        watch: {
            totals: {
                immediate: true,
                handler(totals) {
                    this.minOrderAmountEnabled = totals.withOutVat < this.minOrderAmount;
                }
            }
        }
    };
</script>

<style scoped lang="scss">
@import '../../assets/scss/_variables.scss';

.totals-container {
    background-color: $foodstock-light_blue;
}
</style>
